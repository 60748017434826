import * as React from "react";
import { Link } from "gatsby";
import { getAbsolutePageUrl, getPageUrl } from "src/utils";
import SEO from "components/seo";

// markup
const NotFoundPage = () => {
  return (
    <React.Fragment>
      <SEO
        title="Página não encontrada"
        canonicalUrl={getAbsolutePageUrl("/404/")}
      />

      <h1 className="card-title mb-3">Página não encontrada</h1>
      <h6 className="text-muted card-subtitle mb-2">
        Desculpe, não conseguimos encontrar o que você está procurando.
      </h6>

      <Link to="/">Voltar ao site</Link>
    </React.Fragment>
  );
};

export default NotFoundPage;
